import axios from "../../config/axios";

const apiRPGetReportBySiteId = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page,
  keyword,
  site_id
) => {
  try {
    const result = await axios.get(
      `/report/site?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&site_id=${site_id}`
    );
    console.log(result.data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiRPGetReportNewBySiteId = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page,
  keyword,
  site_id
) => {
  try {
    const result = await axios.get(
      `/report_new/site?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

export { apiRPGetReportBySiteId, apiRPGetReportNewBySiteId };
