import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { DatePicker } from "antd";
import "../Dashboard.css";
import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/th";

import Report from "./Report-v2";
import ChartColumn from "../../../components/Tools/Chart/ChartColumn";
import ChartColumnStack from "../../../components/Tools/Chart/ChartColumnStack";
import ChartPie from "../../../components/Tools/Chart/ChartPie";
import TableReportMachine from "./Table/TableReportMachine-v2";
import TableReportGroup from "./Table/TableReportGroup-v2";
import TableShare from "./Table/TableShare-v2";
import TableSlipUpload from "./Table/TableSlipUpload";
import {
  QueryGraphQL,
  QueryGraphQLById,
  QueryGraphQLBySiteIdPeriod,
  GET_HISTORY_BY_SITE,
  GET_HISTORY_BY_DAY,
  GET_HISTORY_BY_MONTH,
  GET_HISTORY_BY_HOUR,
  GET_HISTORY_BY_PRICE,
  GET_HISTORY_REVENUE,
  GET_SITES,
} from "../../../resolvers/Query";
const { RangePicker } = DatePicker;

const DashboardBranchAdminV2 = () => {
  const { siteId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const start = params.get("start");
  const end = params.get("end");

  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [isLoadingUsage, setIsLoadingUsage] = useState(false);
  const [isLoadingDay, setIsLoadingDay] = useState(false);
  const [isLoadingTime, setIsLoadingTime] = useState(false);
  const [isLoadingYear, setIsLoadingYear] = useState(false);

  const [chartUsage, setChartUsage] = useState(undefined);
  const [chartDay, setChartDay] = useState(undefined);
  const [chartTime, setChartTime] = useState([]);
  const [chartYear, setChartYear] = useState([]);

  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [searchButton, setSearchButton] = useState(false);
  const dataHistoryBySite = QueryGraphQLById(
    GET_HISTORY_BY_SITE,
    siteId,
    startDate,
    endDate
  );
  const [dataList, setDataList] = useState(
    dataHistoryBySite?.data?.getHistoryBySite ?? []
  );
  const site_name = localStorage.getItem("site_name");
  const site_code = localStorage.getItem("site_code");
  const dataGetSite = QueryGraphQL(GET_SITES);
  const dataSites = dataGetSite?.data?.getSites;
  const [siteName, setSiteName] = useState("");
  const [siteCode, setSiteCode] = useState("");
  const [siteType, setSiteType] = useState("");

  const dataHistoryByDay = QueryGraphQLBySiteIdPeriod(
    GET_HISTORY_BY_DAY,
    siteId,
    startDate,
    endDate
  );
  console.log(dataHistoryByDay)
  const dataHistoryByMonth = QueryGraphQLBySiteIdPeriod(
    GET_HISTORY_BY_MONTH,
    siteId,
    startDate,
    endDate
  );
  const dataHistoryByHour = QueryGraphQLBySiteIdPeriod(
    GET_HISTORY_BY_HOUR,
    siteId,
    startDate,
    endDate
  );
  const dataHistoryByPrice = QueryGraphQLBySiteIdPeriod(
    GET_HISTORY_BY_PRICE,
    siteId,
    startDate,
    endDate
  );
  const dataHistoryRevenue = QueryGraphQLBySiteIdPeriod(
    GET_HISTORY_REVENUE,
    siteId,
    startDate,
    endDate
  );
  console.log(dataHistoryRevenue)
  useEffect(() => {
    if (siteId) {
      const dataArr = dataHistoryBySite?.data?.getHistoryBySite ?? [];
      setDataList(dataArr);
    }

    if (siteId && dataSites?.length > 0) {
      dataSites.map((item) => {
        if (item?.id === siteId) {
          setSiteCode(item?.siteCode ?? "");
          setSiteName(item?.site_name ?? "");
          setSiteType(item?.side_type ?? "");
        }
      });
    }
  }, [siteId, dataHistoryBySite, dataSites]);

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">
        Dashboard - รายได้ / การใช้งาน{" "}
        {site_name || siteName
          ? `:
        ${site_name ? site_name : siteName} (${site_code ? site_code : siteCode
          })`
          : ""}
      </div>

      <div className="dash-select-date-container-grid">
        <div className="f-c-c">เลือกวันที่ :</div>
        <div>
          <RangePicker
            style={{ width: "100%" }}
           // format={(date) =>moment(date).add(543, "years").format("D MMM YYYY HH:mm")}
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setStartDate(
                moment(date[0]).startOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              role === "BRANCHADMIN"
                ? current && current < moment("2021-06-25").startOf("day")
                : null
            }
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t dash-select-date-search"
          onClick={() => setSearchButton(!searchButton)}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
      </div>

      <Report report={dataList} isLoading={isLoadingReport} />

      {/* <div className="dash-duo-chart-grid">
        <ChartColumnStack
          // isLoading={isLoadingUsage}
          chartReport={(chartUsage && chartUsage.usage_graph) || []}
          chartTitle="กราฟการใช้ ( บาท )"
          dataLabels={false}
          stackLabels={false}
          tooltipEnabled={true}
          digits={2}
        />
        <ChartPie
          // isLoading={isLoadingUsage}
          chartReport={(chartUsage && chartUsage.graph_baht) || []}
          chartTitle="กราฟแสดงเปอร์เซนต์การใช้เครื่อง ( ครั้ง )"
        />
      </div>

      <ChartColumn
        // isLoading={isLoadingDay}
        chartReport={(chartDay && chartDay.total_baht) || []}
        chartTitle="กราฟแสดงการใช้แบ่งตามวัน ( บาท )"
        nameSeries="วัน"
        dataLabels={true}
        valueData="value"
        tooltipEnabled={false}
      />

      {role === "SUPERADMIN" && (
        <ChartColumn
          // isLoading={isLoadingYear}
          chartReport={chartYear}
          chartTitle="กราฟแสดงการใช้งานย้อนหลัง 1 ปี"
          nameSeries="วัน"
          dataLabels={true}
          valueData="value"
          tooltipEnabled={false}
        />
      )}

      <ChartColumnStack
        // isLoading={isLoadingTime}
        chartReport={chartTime}
        chartTitle="กราฟแสดงการใช้งานหน่วยเป็นช่วงเวลา ( บาท )"
        dataLabels={false}
        stackLabels={false}
        tooltipEnabled={true}
        digits={2}
      /> */}
      <ChartColumn
        // isLoading={isLoadingDay}
        chartReport={
          (dataHistoryByDay && dataHistoryByDay?.data?.getHistoryByDay) || []
        }
        chartTitle="กราฟแสดงการใช้แบ่งตามวัน ( บาท )"
        nameSeries="วัน"
        dataLabels={true}
        valueData="value"
        tooltipEnabled={false}
      />

      <ChartColumn
        // isLoading={isLoadingDay}
        chartReport={
          (dataHistoryByMonth && dataHistoryByMonth?.data?.getHistoryByMonth) ||
          []
        }
        chartTitle="กราฟแสดงการใช้แบ่งตามเดือน ( บาท )"
        nameSeries="วัน"
        dataLabels={true}
        valueData="value"
        tooltipEnabled={false}
      />

      <ChartColumn
        // isLoading={isLoadingDay}
        chartReport={
          (dataHistoryByHour && dataHistoryByHour?.data?.getHistoryByHour) || []
        }
        chartTitle="กราฟแสดงการใช้แบ่งตามชั่วโมง ( บาท )"
        nameSeries="วัน"
        dataLabels={true}
        valueData="value"
        tooltipEnabled={false}
      />

      <TableReportMachine
        startDate={startDate}
        endDate={endDate}
        searchButton={searchButton}
        siteId={siteId}
        dataList={dataList}
      />

      <TableReportGroup dataReport={dataHistoryByPrice} />

      <TableShare dataReport={dataHistoryRevenue} siteType={siteType} />

      {/* <TableSlipUpload siteId={siteId} /> */}
    </div>
  );
};

export default DashboardBranchAdminV2;
