import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "../../Table.css";
import { numberWithCommas } from "../../../../utils/function";

const TableShareV2 = ({ dataReport, siteType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  // console.log("dataReport--🦗🦗", dataReport, tableData);

  useEffect(() => {
    if (dataReport?.data?.getRevenue) {
      const dataArr = dataReport?.data?.getRevenue ?? null;
      const type_site = siteType ?? dataArr?.site_type ?? null;

      let newData = [];
      if (type_site == "FC") {
        newData.push(
          { name: "รายรับรวม", value: dataArr?.total_value ?? 0 },
          { name: "QR Code + APP", value: dataArr?.total_app ?? 0 },
          { name: "ค่าดูแลระบบ", value: dataArr?.fee ?? 0 },
          { name: "ยอดโอนคืน", value: dataArr?.returnAmount ?? 0 }
        );
      } else if (type_site == "OS") {
        newData.push(
          { name: "รายรับรวม", value: dataArr?.total_value ?? 0 },
          { name: "ยอดนับเหรียญได้", value: dataArr?.total_coin ?? 0 },
          { name: "QR Code + APP", value: dataArr?.total_app ?? 0 },
          { name: "ค่าเช่า", value: dataArr?.fee ?? 0 }
        );
      }
      // console.log("newData--🐶", dataArr, newData);
      setTableData(newData);
    }
  }, [dataReport]);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => <div>{index + 1}</div>,
    },
    {
      title: "รายการ",
      ellipsis: true,
      render: (data) => <div>{data.name || "-"}</div>,
    },
    {
      title: "จำนวน ( บาท )",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.value || 0, 2)}</div>,
    },
  ];

  return (
    <div className="dash-chart-box-container">
      <div className="dash-space-between">
        <div className="h-d-t-g">
          <div className="f-c-c">
            <i className="bi bi-journal-text" style={{ lineHeight: "0" }}></i>
          </div>
          <div className="f-s-c">ส่วนแบ่งรายได้</div>
        </div>

        <div className="dash-table-container">
          <Table
            bordered
            size="small"
            rowKey={(record) => record.id}
            loading={isLoading}
            dataSource={tableData}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </div>
      </div>
    </div>
  );
};

export default TableShareV2;
